<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer></v-spacer>
                <span class="text-h5" style="word-break: break-word;">
                    Adicionar Novo Contato
                </span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="Nome"
                            v-model="form.nome"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            label="WhatsApp"
                            v-model="form.whatsapp"
                            prefix="+55"
                            placeholder="(99) 99999-9999"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="salvar" :loading="loading">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Plano',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        form: {},
        loading: false,
    }),

    methods: {
        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`contatos/${id}`, this.form).then(() => {
                    this.$router.push('/contatos');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('contatos', this.form).then(() => {
                // this.$router.push('/contatos');
                this.notify('Salvo com sucesso!');
                this.reset();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
